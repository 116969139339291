import { captureRemixErrorBoundaryError } from "@sentry/remix";
import {
  data,
  Links,
  LinksFunction,
  LoaderFunctionArgs,
  Meta,
  MetaFunction,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
  useRouteError,
} from "react-router";
import stylesheet from "~/tailwind.css?url";
import flags from "react-flagpack/dist/style.css?url";
import { IntercomProvider } from "react-use-intercom";
import {
  Theme,
  ThemeBody,
  ThemeHead,
  ThemeProvider,
  useTheme,
} from "~/utils/theme-provider";
import { ThemeSession } from "~/utils/theme.server";
import { useEffect } from "react";
import { i18n, localeCookie } from "~/i18next.server";
import { useTranslation } from "react-i18next";

import { MixPanel } from "~/utils/MixPanel";
import {
  FlashMessage,
  FlashMessageSession,
} from "./sessions/FlashMessageSession.server";
import { FlashMessageToast } from "~/components/FlashMessageToast";
import { APIProvider } from "@vis.gl/react-google-maps";

export const links: LinksFunction = () => [
  { rel: "stylesheet", href: stylesheet },
  { rel: "stylesheet", href: flags },
  { rel: "manifest", href: "/manifest.webmanifest" },
];
export type RootLoaderData = {
  theme: Awaited<Theme | null>;
  locale: string;
  path: string;
  flashMessage: FlashMessage | null;
  lngs: object;
  publicKeys: {
    MIXPANEL_PROJECT_TOKEN: string;
    GOOGLE_RECAPTCHA_CLIENT: string;
    ENVIRONMENT: string;
    GOOGLE_LOCATION_API_KEY: string;
  };
};
const INTERCOM_APP_ID = "d8ylbsny";
export function useChangeLanguage(locale: string) {
  let { i18n } = useTranslation("translation");
  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [locale, i18n]);
}

export const meta: MetaFunction = () => {
  return [
    {
      name: "viewport",
      content: "width=device-width, initial-scale=1, shrink-to-fit=no",
    },
    {
      charset: "utf-8",
    },
    {
      title: "Mingle Sport Homebase",
    },
    {
      name: "mobile-web-app-capable",
      content: "yes",
    },
    {
      name: "apple-mobile-web-app-capable",
      content: "yes",
    },
    {
      name: "msapplication-starturl",
      content: "/",
    },
  ];
};

export const loader = async ({ request, context }: LoaderFunctionArgs) => {
  const themeSession = await ThemeSession(request);
  let locale = await i18n.getLocale(request);
  const flashSession = await FlashMessageSession(request);

  return data<RootLoaderData>(
    {
      flashMessage: flashSession?.getFlashMessage(),
      locale,
      theme: themeSession.getTheme(),
      publicKeys: {
        MIXPANEL_PROJECT_TOKEN:
          context?.cloudflare?.env?.MIXPANEL_PROJECT_TOKEN,
        GOOGLE_RECAPTCHA_CLIENT:
          context?.cloudflare?.env?.GOOGLE_RECAPTCHA_CLIENT,
        ENVIRONMENT: context?.cloudflare?.env?.ENVIRONMENT,
        GOOGLE_LOCATION_API_KEY:
          context?.cloudflare?.env?.GOOGLE_LOCATION_API_KEY,
      },

      lngs: {
        en: { nativeName: "English" },
        nl: { nativeName: "Nederlands" },
      },
      path: request.url,
    },
    {
      headers: [
        ["Set-Cookie", await flashSession.deleteFlashMessage()],
        ["Set-Cookie", await localeCookie.serialize(locale)],
      ],
    }
  );
};

function App() {
  const { locale, publicKeys, flashMessage } = useLoaderData<RootLoaderData>();
  const [theme] = useTheme();
  const { pathname } = useLocation();
  let { i18n, t } = useTranslation("translation");
  useChangeLanguage(locale);

  useEffect(() => {
    MixPanel.track("hbPageView", { pathname });
  }, [pathname]);

  return (
    <html
      lang={locale}
      className={`scroll-smooth h-full ${theme ?? ""} overscroll-auto `}
      data-theme={theme ?? ""}
      dir={i18n.dir()}
    >
      <head>
        <Meta />

        <ThemeHead ssrTheme={Boolean(theme)} />
        <link rel="icon" href="/favicon.ico" />
        <Links />
      </head>
      <body>
        <Outlet />

        <ThemeBody ssrTheme={Boolean(theme)} />
        <ScrollRestoration />
        <Scripts />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(publicKeys)}`,
          }}
        />
        <FlashMessageToast flashMessage={flashMessage} />
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <div>Something went wrong</div>;
};

export default function AppWithProviders() {
  const { theme, publicKeys } = useLoaderData<typeof loader>();
  return (
    <ThemeProvider specifiedTheme={theme}>
      <IntercomProvider
        appId={INTERCOM_APP_ID}
        initializeDelay={500}
        autoBoot={true}
        onHide={() => {
          // window.Intercom("shutdown");
        }}
      >
        <APIProvider apiKey={publicKeys?.GOOGLE_LOCATION_API_KEY}>
          <App />
        </APIProvider>
      </IntercomProvider>
    </ThemeProvider>
  );
}
